<div
    class="bg-white rounded-lg shadow-lg flex flex-col"
    [ngClass]="{
        'max-h-75vh m-4': options.size !== 'full',
        'max-w-lg': options.size === 'sm',
        'max-w-2xl': options.size === 'md' || !options.size,
        'max-w-5xl': options.size === 'lg',
        'max-w-screen': options.size === 'xl',
        'w-[calc(100vw-30px)]': options.size === 'full',
        'h-[70vh] md:h-[85vh] lg:h-screen': options.size === 'full'
    }"
>
    <div class="flex justify-between items-baseline p-4 space-x-4">
        <h5 class="text-lg"><ng-container *ngTemplateOutlet="titleTemplateRef$ | async"></ng-container></h5>
        <button
            *ngIf="options?.closable"
            type="button"
            (click)="closeModal()"
            class="text-gray-500"
            data-dismiss="modal"
            aria-label="Close"
        >
            <fa-icon [icon]="times"></fa-icon>
        </button>
    </div>
    <div class="flex-1 overflow-y-auto" [ngClass]="options.size === 'full' ? 'p-0' : 'p-4'">
        <vsf-dialog-component-outlet
            [component]="childComponentType"
            (create)="onCreate($event)"
        ></vsf-dialog-component-outlet>
    </div>
    <div class="p-4 text-right">
        <ng-container *ngTemplateOutlet="buttonsTemplateRef$ | async"></ng-container>
    </div>
</div>
